import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useForm } from '@formspree/react';

import content_data from '../content/content-data.js';

const FormRFQ = function( { service, location } ) {

	const localContent = content_data[location.pathname].form;

	const months = () => {
		return (new Array(12).fill(undefined).map( (value, index) => {
			let objDate = new Date();
			objDate.setDate(1);objDate.setMonth(index);

			return {index: index, name: objDate.toLocaleString("en-GB", { month: "long" })}; 
		}))
	};

	const years = () => {
			return (new Array(3).fill(undefined).map( (value, index) => {
			let objDate = new Date();

			return (objDate.getFullYear()+index);
		}))
	};

	const [state, formspreeHandleSubmit] = useForm("mbjqlbyv");

	const ValidationSchema = Yup.object().shape({
		 client_email: Yup.string().email(localContent.validation.email).required(localContent.validation.required),
	 });

	if(state.succeeded) {
		(document.querySelector("#quote .options") as HTMLElement).style.display;

		return(
			<React.Fragment>{localContent.form.success}</React.Fragment>
		)
	}

	if(state.submitting) {
		const isBrowser = typeof window !== "undefined";

			if(isBrowser) {
			const request = window.indexedDB.open("weboloDB", 1);
			console.log('connecting');

			request.onupgradeneeded = function(event) {
				let db = request.result;
				console.log('upgrading');
					
				if (!db.objectStoreNames.contains('poptavky')) { 
					db.createObjectStore('poptavky', {keyPath: 'id'});
				}	 
			};

			request.onerror = function(event) {
				console.log("Database error: ", (event.target as any).error);
			};

			request.onsuccess = function(event) {
				console.log('writing');

				var db = (event.target as any).result;
				var objectStore = db
				.transaction("poptavky", "readwrite")
				.objectStore("poptavky");

				let formData = [];
				let elements = document.querySelectorAll('[name]');

				elements.forEach((element: Element) => {
					formData.push((element as HTMLInputElement).value);
				});

				objectStore.add({id: Date.now().toString(), status: "new", data: formData.toString()});
			};
		}

		if(isBrowser) {
			navigator.serviceWorker.ready.then(function(registration) {
				registration.sync.register('sync-poptavky');
				console.log('sync');
			});
		}
	}

	return(
		<Formik 
			initialValues={{
			 service: '',
			 rfq_web_solution: '',
			 rfq_audit: '',
			 rfq_audit_subject: '',
			 rfq_deadline_month: '',
			 rfq_deadline_year: '2021',
			 rfq_description: '',
			 rfq_budget: '',
			 rfq_other: '',
			 client_company: '',
			 client_email: '',
			 client_tel: '',
			 }}
			 validationSchema={ValidationSchema}
			 onSubmit={formspreeHandleSubmit}
		>
			{({ errors, touched }) => (
			<Form className={(state.submitting) ? "form_submitting" : ""}>
			
				<input type="hidden" name="service" value={service} />

				<h2>{localContent.inputs.heading_outline}</h2>
				
				{(service === 0) &&
				<div className="mb-3">
					<div>
						<label className="form-label" htmlFor="floatingInputValue">{localContent.inputs.label_service[service]}:</label>
					</div>
					<div>
						{localContent.inputs.services[service]?.map( (service_item) => (
							<div className="form-check">
								<Field className="form-check-input" type="radio" name="rfq_web_solution" value={service_item.id} id={`rfq_subject_${service_item.id}`} />
								<label className="form-check-label" htmlFor={`rfq_subject_${service_item.id}`}>
									{service_item.name}
								</label>
							</div>
						))}
					</div>
				</div>}

				{(service === 2) &&
					<React.Fragment>
					<div className="mb-3">
						<div>
							<label className="form-label" htmlFor="rfq_audit_security">{localContent.inputs.label_service[service]}:</label>
						</div>
						<div>

							{localContent.inputs.services[service]?.map( (service_item) => (
								<div>
									<Field type="checkbox" className="form-check-input" value={service_item.id} name="rfq_audit" id={`rfq_audit_${service_item.id}`} />
									<label className="form-check-label" htmlFor={`rfq_audit_${service_item.id}`}>
										{service_item.name}
									</label>
								</div>
							))}
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="rfq_audit_subject" className="form-label">{localContent.inputs.rfq_audit_subject.label}</label>
						<Field type="text" className="form-control" name="rfq_audit_subject" id="rfq_audit_subject" placeholder="http://web.com" />
						<small>{localContent.inputs.rfq_audit_subject.hint}</small>
					</div>
				</React.Fragment>}

				{(service === 2) ||
				<React.Fragment>
					<div className="mb-3">
						<label className="form-label" htmlFor="rfq_deadline_month">{localContent.inputs.rfq_deadline.label}</label>

						<div className="deadline">
							<Field as="select" className="form-select" name="rfq_deadline_month" id="rfq_deadline_month" aria-label={localContent.inputs.rfq_deadline.month_aria}>
							<option value="0">{localContent.inputs.rfq_deadline.month_init}</option>
							{months().map( month => (
								<option value={month.name}>{month.name}</option>
							))}
							</Field>

							<Field as="select" className="form-select" name="rfq_deadline_year" id="rfq_deadline_year" aria-label={localContent.inputs.rfq_deadline.year_aria}>
							{years().map( (year, index) => (
								<option value={year} key={index}>{year}</option>
							))}
							</Field>
						</div>

						<small>{localContent.inputs.rfq_deadline.hint}</small>
					</div>

					<div className="mb-3">
						<label htmlFor="rfq_description" className="form-label">{localContent.inputs.rfq_description.label}</label>
						<Field as="textarea"className="form-control" name="rfq_description" id="rfq_description" rows="3"></Field>
					</div>

					<div className="mb-3 budget">
						<label htmlFor="rfq_budget" className="form-label">{localContent.inputs.rfq_budget.label}</label>
						<div className="input-group">
							<Field type="text" className="form-control" name="rfq_budget" id="rfq_budget" placeholder="" />
							<span className="input-group-text">{localContent.inputs.rfq_budget.currency}</span>
						</div>
						<small>{localContent.inputs.rfq_budget.hint}</small>
					</div>
				</React.Fragment>}

				<div className="mb-3">
					<label htmlFor="rfq_other" className="form-label">{localContent.inputs.rfq_other.label}</label>
					<Field as="textarea" className="form-control" name="rfq_other" id="rfq_other" rows="3"></Field>
				</div>

				<h2>{localContent.inputs.heading_contact}</h2>
				<p>{localContent.inputs.heading_contact_hint}</p>
				
				<div className="mb-3 form-floating">
					<Field type="text" className="form-control" name="client_company" id="client_company" placeholder="Company" />
					<label htmlFor="client_company">{localContent.inputs.client_company.label}</label>
					<small>{localContent.inputs.client_company.hint}</small>
				</div>

				<div className={"mb-3 form-floating "+ ((errors.client_email && touched.client_email) ? " kurde_takhle_to_nejde" : "")}>
					<Field type="email" className="form-control" name="client_email" id="client_email" placeholder="your@email.com" />
					<label htmlFor="client_email">{localContent.inputs.client_email.label}</label>
					{errors.client_email && touched.client_email ? 
						<small className="error">{errors.client_email}</small> : <small>{localContent.inputs.client_email.hint}</small>
					}
				</div>

				<div className="mb-3 form-floating">
					<Field type="tel" className="form-control" name="client_tel" id="client_tel" placeholder="+49 152 123456" />
					<label htmlFor="client_tel">{localContent.inputs.client_tel.label}</label>
					<small>{localContent.inputs.client_tel.hint}</small>
				</div>

				<div className={"mt-4 text-center" + ((errors.client_email && touched.client_email) ? " kurde_takhle_to_nejde" : "")}>
					<button type="submit" className="btn btn-primary" disabled={state.submitting}>{(state.submitting) ? (<React.Fragment><div className="loader"></div> {localContent.inputs.submit_btn.status}</React.Fragment>) : localContent.inputs.submit_btn.label}</button>
					<small className="mt-2 takhle_teda_ne">{localContent.inputs.submit_btn.warning}</small>
				</div>

			</Form>)}
		</Formik>
	)
}

export default FormRFQ
import React, { useState } from "react";

import content_data from '../content/content-data.js';

import Layout from '../components/layout.js';
import FormRFQ from '../components/inquiry_rfq_form.tsx';

const RequestForQuote = ({ location }) => {

  const [serviceSelected, serviceSelected_change] = useState(false);
  const localContent = content_data[location.pathname].menu;

  function toggle_options(e) {

    const _this   = e.target.closest(".card_quote");
    const cards   = document.querySelectorAll(".card_quote");
    const form    = document.querySelector(".form");
    const heading = document.querySelector(".options h1");

    cards.forEach( ( card, index ) => {
      if(card !== _this) {
        card.classList.toggle("fuckYou");
      }
      else {
        card.classList.toggle("selected");
        card.scrollIntoView({behavior: "smooth", block: "start"});
        serviceSelected_change(index);
      }
    });

    form.classList.toggle("opened");
    heading.classList.toggle("hidden");
  }

  return (
    <Layout location={location}>
      <div id="quote">

        <div class="hint">
          {localContent.hint}
        </div>
          
        <section class="options">

          <h1>{localContent.heading}</h1>
          
          <div class="cards_quote">

            {content_data["/"].whatWeDo.services.map( (service) => (
              <div class={`card_quote theme-${service.theme}`}
                onClick={toggle_options} 
                onKeyDown={(e) => ((e.key || e.keyCode === 13) && toggle_options)}
                role="button"
                tabIndex={0}
              >
                <div class="icon" data-title={`${service.title_hint}`}>{service.icon}</div>
                <div>
                  <h4>{service.name}</h4>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section class="form">
          <FormRFQ location={location} service={serviceSelected} />
        </section>

      </div>
    </Layout>
  )
}

export default RequestForQuote